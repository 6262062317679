import React, { useEffect, useState } from 'react';
import "./index.css";
import { Slide, Fade, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { useLocation } from 'react-router-dom';
import { Button, CircularProgress, Container, Grid, IconButton, Menu, Typography } from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import StarIcon from '@mui/icons-material/Star';
import { ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsLegend} from '@mui/x-charts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import {  LineChart as LinesCharts } from '@mui/x-charts/LineChart';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, AreaChart, Area } from 'recharts';

import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';


// Local 

import TableComponent from '../../Components/TableDataComp';
import { AmountSaperator, getStartDate } from '../../Utilities';
import imageBack from "../../Images/duabi.jpg";
import { httpRequest } from '../Server/Axios';
import { GET_COMMUNITY_TRENDS_API, GET_PROPERTIES_PAYMENT_PLAN, GET_PROPERTY_MONITOR_STATISTICS_API, GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API, GET_RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT_API } from '../Server/Apis';
import { List_Of_Payment_Plan, List_Of_Payment_Plan1 } from '../../LocalDB';
import LottieAnimationLoader from '../../Components/lottieAnimation';
import BarLoader from "../../Images/bar.png";
import LineChartLoader from "../../Images/lineChart.png";
import TableLoader from "../../Images/tableLoader.png";
import { propertyDocumentsUrl, propertyImagesUrl } from '../Server/baseUrls';
import ListingBarChartComp from './ListingBarChartsComp';
import CommunityPriceTrends from './CommunityPriceTrends';

// const divStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundSize: 'cover',
//     height: '100%',
//     width: '100%',
//   };

const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

// const priceUp = [ '5%', '10%', '11%', '21%', '90%', '5%', '10%', '11%', '21%', '90%', '5%', '10%'];

// const priceDown = [ '15%', '10%', '30%', '21%', '90%', '5%', '10%', '11%', '21%', '90%', '5%', '10%' ];

// const lineChartsParams = {
// //   series: [
// //     {
// //       label: 'PRICE UP',
// //       data: priceUp,
// //       showMark: false,
// //     },
// //     {
// //       label: 'PRICE DOWN',
// //       data: priceDown,
// //       showMark: false,
// //     },
// //   ],
//   height: 400,
// };

const data = {
    "Dubai Hills": [
        { year: 2013, price: 3500000 },
        { year: 2014, price: 4800000 },
        { year: 2015, price: 6000000 },
        { year: 2061, price: 7200000 },
        { year: 2017, price: 4400000 },
        { year: 2018, price: 5500000 },
        { year: 2019, price: 6800000 },
        { year: 2020, price: 6000000 },
        { year: 2021, price: 5200000 },
        { year: 2022, price: 5400000 },
        { year: 2023, price: 5600000 },
        { year: 2024, price: 4900000 }
    ],

    "Downtown": [
      { year: 2013, price: 4500000 },
      { year: 2014, price: 4800000 },
      { year: 2015, price: 5000000 },
      { year: 2061, price: 5200000 },
      { year: 2017, price: 5400000 },
      { year: 2018, price: 4500000 },
      { year: 2019, price: 4800000 },
      { year: 2020, price: 5000000 },
      { year: 2021, price: 5200000 },
      { year: 2022, price: 5400000 },
      { year: 2023, price: 5600000 },
      { year: 2024, price: 4900000 }
    ],
    "Marina": [
        { year: 2013, price: 4200000 },
        { year: 2014, price: 4000000 },
        { year: 2015, price: 4200000 },
        { year: 2016, price: 4000000 },
        { year: 2017, price: 4200000 },
        { year: 2018, price: 4000000 },
        { year: 2019, price: 4200000 },
        { year: 2020, price: 4500000 },
        { year: 2021, price: 4700000 },
        { year: 2022, price: 4900000 },
        { year: 2023, price: 6300000 },
        { year: 2024, price: 4400000 }
    ],
    "Palm Jumeirah": [
        { year: 2013, price: 4200000 },
        { year: 2014, price: 4000000 },
        { year: 2015, price: 4200000 },
        { year: 2016, price: 4000000 },
        { year: 2017, price: 4200000 },
        { year: 2018, price: 5500000 },
        { year: 2019, price: 5800000 },
        { year: 2020, price: 6000000 },
        { year: 2021, price: 6200000 },
        { year: 2022, price: 6400000 },
        { year: 2023, price: 5600000 },
        { year: 2024, price: 4900000 }
    ],
  };

//   const SalesVolumeData = [
//     { area: 'Bluewaters Island', price: 6045.63 },
//     { area: 'Business Bay', price: 1473 },
//     { area: 'City walk', price: 2169 },
//     { area: 'DownTown', price: 2883 },
//     { area: 'Creek harbour', price: 2101 },
//     { area: 'Dubai Hills', price: 1831 },
//     { area: 'Marina', price: 1544 },
//     { area: 'Harbour', price: 3996 },
//   ];

const monthFormatter = (monthIndex) => months[monthIndex - 1];

// const rentalData = {
//     "Dubai Hills": [
//         { month: 'Jan', price: 7000 },
//         { month: 'Feb', price: 6200 },
//         { month: 'Mar', price: 8100 },
//         { month: 'Apr', price: 8300 },
//         { month: 'May', price: 8400 },
//         { month: 'Jun', price: 9500 },
//         { month: 'Jul', price: 8600 },
//         { month: 'Aug', price: 8700 },
//         { month: 'Sep', price: 7800 },
//         { month: 'Oct', price: 8900 },
//         { month: 'Nov', price: 9000 },
//         { month: 'Dec', price: 9100 }
//       ],
//     "Downtown": [
//       { month: 'Jan', price: 8000 },
//       { month: 'Feb', price: 8200 },
//       { month: 'Mar', price: 8100 },
//       { month: 'Apr', price: 8300 },
//       { month: 'May', price: 8400 },
//       { month: 'Jun', price: 8500 },
//       { month: 'Jul', price: 8600 },
//       { month: 'Aug', price: 8700 },
//       { month: 'Sep', price: 8800 },
//       { month: 'Oct', price: 8900 },
//       { month: 'Nov', price: 9000 },
//       { month: 'Dec', price: 9100 }
//     ],
//     "Marina": [
//       { month: 'Jan', price: 7500 },
//       { month: 'Feb', price: 7600 },
//       { month: 'Mar', price: 7700 },
//       { month: 'Apr', price: 7800 },
//       { month: 'May', price: 7900 },
//       { month: 'Jun', price: 8000 },
//       { month: 'Jul', price: 8100 },
//       { month: 'Aug', price: 8200 },
//       { month: 'Sep', price: 8300 },
//       { month: 'Oct', price: 8400 },
//       { month: 'Nov', price: 8500 },
//       { month: 'Dec', price: 8600 }
//     ],
//     "Palm Jumeirah": [
//       { month: 'Jan', price: 9000 },
//       { month: 'Feb', price: 9200 },
//       { month: 'Mar', price: 9100 },
//       { month: 'Apr', price: 9300 },
//       { month: 'May', price: 9400 },
//       { month: 'Jun', price: 9500 },
//       { month: 'Jul', price: 9600 },
//       { month: 'Aug', price: 9700 },
//       { month: 'Sep', price: 9800 },
//       { month: 'Oct', price: 9900 },
//       { month: 'Nov', price: 10000 },
//       { month: 'Dec', price: 10100 }
//     ],
//   };

// const rentalChangesData = [
//     { area: 'Al Barari', price: 245090 },
//     { area: 'jumeira bay', price: 865720 },  
//     { area: 'Downtown', price: 162127 },
//     { area: 'Palm Jumeirah', price: 221877 },
//     { area: 'Jumeirah', price: 124955 },
//     { area: 'Emirates Living', price: 108127 },
//     { area: 'Dubai WaterFront', price: 48429 },
//     { area: 'Zabeel', price: 144163 },
//     { area: 'Pearl jumeirah', price: 366667 },
//     { area: 'La Mer', price: 179427 },
// ]

// const rentalChangesDataPerSqft = [
   
//     { area: 'jumeira bay', price: 401 },  
//     { area: 'Pearl jumeirah', price: 205 },
//     { area: 'Downtown', price: 136 },
//     { area: 'Palm Jumeirah', price: 127 },
//     { area: 'Al Barari', price: 120 },
//     { area: 'Jumeirah', price: 84 },
//     { area: 'Emirates Living', price: 101 },
//     { area: 'Dubai WaterFront', price: 48 },
//     { area: 'JVT', price: 72 },
//     { area: 'Meydan', price: 78 },
// ]

// const salesChangesData = [
   
//     { area: 'Downtown', price: 3535011 },
//     { area: 'Jumeirah Golf Estates', price: 2140000 },
//     { area: 'Palm Jumeirah', price: 5387829 },
//     { area: 'Bluewaters Island', price: 10242222 },
//     { area: 'Dubai Harbour', price: 5721745 },
//     { area: 'La Mer', price: 5261667 },
//     { area: 'Emirates Living', price: 2022800},
//     { area: 'Dubai Marina', price: 3149304 },
//     { area: 'Dubai Islands', price: 5049997 },  
//     { area: 'Jumeirah Beach Residence', price: 3589333 },
// ]

const useStyles = makeStyles((theme) => ({
    chartContainer: {
      width: '100%',
      height: '40vh',     
      marginTop: '2rem',
    },
    formControl: {
      marginTop: '2rem',
      minWidth: 120,
    },
  }));

const ListingsDetails = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const exchangeRate = 3.677;

    const propertyDetails = state.propertyDetails;
    const tokenInfo = useSelector((state) => state.login.token);

    // console.log("******** Property Details ********** ", propertyDetails);

    const [selectedArea, setSelectedArea] = useState(Object.keys(data)[0]);
    const [selectedAreaForRental, setSelectedAreaForRental] = useState(Object.keys(data)[0]);
    const [startDate, setStartDate] = useState(2018);
    const [endDate, setEndDate] = useState(2022);
    const [animate, setAnimate] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [nextPayment, setNextPayment] = useState("");
    const [dueDate, setDueDate] = useState("");

    // New State For Property Statistics
    const [sSalesVolumeData, setSalesVolumeData] = useState([]);
    const [sRentalChangesData, setRentalChangesData] = useState([]);
    const [sSaleChangesData, setSaleChangesData] = useState([]);
    const [sTransactionData, setTransactionData] = useState([]);
    const [priceTrendsData, setPriceTrendsData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [loaderRent, setLoaderRent] = useState(false);
    const [errorRent, setErrorRent] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [error1, setError1] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [error2, setError2] = useState(false);
    const [nextInstallmentObj, setNextInstallmentObj] = useState(null)

    const [priceTrendsChangesDataArea, setPriceTrendsChangesDataArea] = useState([]);
    const [priceTrendsData_Area, setPriceTrendsData_Area] = useState([]);
    const [loaderArea, setLoaderArea] = useState(false);
    const [errorArea, setErrorArea] = useState(false);
    const [sChangeIsArea, setChangeIsArea] = useState(false);

    const [priceTrendsChangesDataProject, setPriceTrendsChangesDataProject] = useState([]);
    const [priceTrendsData_Project, setPriceTrendsData_Project] = useState([]);
    const [loaderProject, setLoaderProject] = useState(false);
    const [errorProject, setErrorProject] = useState(false);
    const [sChangeIsProject, setChangeIsProject] = useState(false);

    const [PropertyPrice, setpropertyPrice] = useState([]);
    const [loaderProperty, setLoaderProperty] = useState(false);

    const [currency, setCurrency] = useState('AED');
    const [currencyMenu, setCurrnecyMenu] = useState(null);
    const [propertyValue, setPropertyValue] = useState(0);

    // New States For Community Trendws API

    const [communityLoader, setCommunityLoader] = useState(false);
    const [communityTrendsData, setCommunityTrendsData] = useState([]);


    const handleCurrencyMenu = (event) => {
        setCurrnecyMenu(event.currentTarget);
    };
    
    const hadleCloseCurrencyMenu = () => {
        setCurrnecyMenu(null);
    };

    const handleToggleCurrency = () => {
        setCurrency((prevCurrency) => (prevCurrency === 'AED' ? 'USD' : 'AED'));
        hadleCloseCurrencyMenu()
    };

    const formatPrice = (price) => {   
        if (price == null) return 'AED 0';

        if (currency === 'AED') {
            return `AED ${AmountSaperator(price)}`;
        } else {
            const priceInUSD = price / exchangeRate;
            return `USD ${AmountSaperator(priceInUSD.toFixed(2))}`;
        }
    };

    useEffect(() => {
        // Scroll to the top when the component mounts
        setAnimate(true);
        window.scrollTo(0, 0);
        getProperty_Payment_Plan();
        // Property_Monitor_Statistics_API();
        fLatestTransaction_Statistics_API();
        // Property_Monitor_Statistics_FOR_RENT_API();
        Property_Price_Trends_In_Dubai_As_Per_Project();
        Property_Price_Trends_In_Dubai_As_Per_Area();
        // RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT();    

        Property_Monitor_API_For_Community_Trends();
    }, []);

    const getProperty_Payment_Plan = async() => {
        setLoader2(true)
        const API_PROPERTY_PAYMENT_PLAN = GET_PROPERTIES_PAYMENT_PLAN(propertyDetails.id, tokenInfo);
        
        try {
           const response = await httpRequest(API_PROPERTY_PAYMENT_PLAN);
           if(response.success){
            // console.log("***** Response  ******* ", response.property.property_plans)
            // setPaymentData(response.payment_plan.property_payment_details)
            setPaymentData(response.property.property_plans)
            getNextInstallment(response.property.property_plans);
            setNextPayment(response.payment_plan.amount);
            setDueDate(response.payment_plan.due_date)
            setLoader2(false)
           }
           else{
            setLoader2(false)
           }
        } catch (error) {
            setLoader2(false)
            setError2(error)
        }
    }

    const getNextInstallment = (xData) => {
        const date = new Date();
        const validInstallments = xData.filter((item) => {
          return item.date && moment(date).format('YYYY-MM-DD') < item.date || item.paid_status == 'Unpaid';
        });
        if (validInstallments.length > 0) {
            // validInstallments.sort((a, b) => new Date(a.date) - new Date(b.date));
            setNextInstallmentObj(validInstallments[0]);
        } else {
            setNextInstallmentObj(xData[0] || { message: "No upcoming installment" });
        }
        
      };

    const endDate1 = new Date(); // Current date
    const startDate1 = getStartDate(endDate1);

    const Property_Monitor_Statistics_API = async() => {
        setLoader(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_MONITOR_STATISTICS_API(
            moment(startDate1).format('yyyy-M-DD'),
            // propertyDetails.status,
            '',
            moment(endDate1).format('yyyy-M-DD'),
            10,
            'TRA',
            propertyDetails.bedrooms,
            propertyDetails.bedrooms,
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            // propertyDetails.location,
            propertyDetails.title == "ELA"? "VELA": propertyDetails.title 
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            // console.log("**** Property Monitor Statistics Response ****** ", response);

            if(response.status == 1){
                
                const salesVolumeData = response.data.map(item => ({ 
                    area: item.master_development, 
                    // price: parseInt(item.sales_price_sqft_unit) || 0 // Convert to integer, default to 0 if conversion fails
                    price: parseInt(item.total_sales_price) || 0 // Convert to integer, default to 0 if conversion fails
                }));
                setSalesVolumeData(salesVolumeData);
                
                const dubaiRentalChangesData = response.data.map(item => ({ 
                    area: item.master_development, 
                    price: parseInt(item.total_sales_price) || 0 // Convert to integer, default to 0 if conversion fails
                }));
                // setRentalChangesData(dubaiRentalChangesData);
    
                const dubaiSaleChangesData = response.data.map(item => ({ 
                    area: item.master_development, 
                    price: parseInt(item.total_sales_price) || 0 // Convert to integer, default to 0 if conversion fails
                }));
                setSaleChangesData(dubaiSaleChangesData);
    
                
                // const dubaiTransactionData = response.data.map(item => ({ 
                //     id: item.id,
                //     area: item.sub_loc_1, 
                //     transactionDate: moment(item.evidence_date).format('Do [of] MMMM YYYY'),
                //     amount: parseInt(item.total_sales_price) || 0, // Convert to integer, default to 0 if conversion fails,
                //     master_development: item.master_development,
                //     Community: item.sub_loc_2,
                //     Sub_Community: item.sub_loc_3,
                //     beds: item.no_beds,
                //     property_type: item.property_type,
                //     area_sqft: item.unit_bua_sqft,
                //     area_sqmt: item.unit_bua_sqm
                // }));
                // setTransactionData(dubaiTransactionData);
                setLoader(false)
            }
            else{
                setLoader(false);
                setError('There is No Data');
            }

        } catch (error) {
            setLoader(false);
            setError(error);
        }
    
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getFullYear();
        
        // return `${day}-${month}-${year}`; 
        return `${year}-${month}-${day}`;
    }

    const fLatestTransaction_Statistics_API = async() => {
        
        const today = new Date();
        const twelveMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate());

        const endDate = formatDate(today);
        const startDate = formatDate(twelveMonthsAgo);

        // console.log(`EndDate: ${endDate}`);
        // console.log(`StartDate: ${startDate}`);

        setLoader(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_MONITOR_STATISTICS_API(
            // '24-07-2023',
            startDate,
            '',
            // '23-07-2024',
            endDate,
            25,
            '',
            propertyDetails.bedrooms,
            propertyDetails.bedrooms,
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            // propertyDetails.location,
            propertyDetails.title == "ELA"? "VELA": propertyDetails.title 
        );

        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            // console.log("**** Property Monitor Statistics Response ****** ", response);

            if(response.status == 1){
                
                const dubaiTransactionData = response.data.map(item => ({ 
                    id: item.id,
                    area: item.sub_loc_1, 
                    transactionDate: moment(item.evidence_date).format('Do [of] MMMM YYYY'),
                    amount: parseInt(item.total_sales_price) || 0, // Convert to integer, default to 0 if conversion fails,
                    master_development: item.master_development,
                    Community: item.sub_loc_2,
                    Sub_Community: item.sub_loc_3,
                    beds: item.no_beds,
                    property_type: item.property_type,
                    area_sqft: item.unit_bua_sqft,
                    area_sqmt: item.unit_bua_sqm
                }));
                setTransactionData(dubaiTransactionData);
                setLoader(false)
            }
            else{
                setLoader(false);
                setError('There is No Data');
            }

        } catch (error) {
            setLoader(false);
            setError(error);
        }
    }

    const Property_Monitor_Statistics_FOR_RENT_API = async() => {
        setLoaderRent(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_MONITOR_STATISTICS_API(
            moment(startDate1).format('yyyy-M-DD'),
            'Rent',
            '',
            moment(endDate1).format('yyyy-M-DD'),
            10,
            'TRA',
            propertyDetails.bedrooms,
            propertyDetails.bedrooms,
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);

            if(response.status == 1){
                const dubaiRentalChangesData = response.data.map(item => ({ 
                    area: item.master_development, 
                    price: parseInt(item.total_sales_price) || 0 // Convert to integer, default to 0 if conversion fails
                }));
                setRentalChangesData(dubaiRentalChangesData);
                setLoaderRent(false)
            }
            else{
                setLoaderRent(false);
                setErrorRent('There is No Data');
            }

        } catch (error) {
            setLoaderRent(false);
            setErrorRent(error);
        }
    
    }

    // THis API is only For Line Chart...
    // const Property_Price_Trends_In_Dubai = async() => {
    //     setLoader1(true)
    //     const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API(
    //         // propertyDetails.developer,
    //         "",
    //         propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
    //         propertyDetails.location,
    //         propertyDetails.bedrooms,
    //     );
        
    //     try {
    //         const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
    //         if(response.status == 1){
    //             // Extract data for chart
    //             const chartData = response.data.map(item => ({
    //                 x: item.evidence_month,
    //                 y: item.total_value 
    //             }));

    //             setPriceTrendsData(chartData);

    //             setLoader1(false)
    //         }
    //         else{
    //             setLoader1(false);
    //             setError1('There is No Data');
    //         }

    //     } catch (error) {
    //         setLoader1(false);
    //         setError1(error);
    //     }
    // }

    // This API is only for Bar Chart
    const Property_Price_Trends_In_Dubai_As_Per_Area = async() => {
        setLoaderArea(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API(
            "",
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            propertyDetails.location,
            propertyDetails.bedrooms,
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            if(response.status == 1){
                // Extract data for chart
                const filteredData = response.data.filter((item) =>  item.avg_price > 0 );
                const chartData = filteredData.map(item => ({
                    x: item.evidence_month,
                    y: item.avg_price 
                }));
                setPriceTrendsChangesDataArea(filteredData)

                setPriceTrendsData_Area(chartData);

                setLoaderArea(false)

                setChangeIsArea(true)
            }
            else{
                setLoaderArea(false);
                setErrorArea('There is No Data');
                setChangeIsArea(true)
            }

        } catch (error) {
            setLoaderArea(false);
            setErrorArea(error);
            setChangeIsArea(true)
        }
    }

    const CalculatePercentage = (xData, period) => {
        if(period == 2 && xData.length > period){
            // console.log("*********** Working One ********* ", period)
            const first_val = xData[xData.length - 1];
            // console.log("***** First Value ******* ", first_val);
            const second_val = xData[xData.length - (period)];
            // console.log("***** Second Value ******* ", second_val);
            const difference = first_val.avg_price - second_val.avg_price;
            // console.log("***** difference Value ******* ", difference);
            const newValue = difference / second_val.avg_price;
            // console.log("***** newValue Value ******* ", newValue);
            const changesInPercentage = Number(newValue) * 100;
            // console.log("***** Percentage Value ******* ", changesInPercentage);
            return changesInPercentage;
          }
    }

    const Property_Price_Trends_In_Dubai_As_Per_Project = async() => {
        setLoaderProject(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API(
            "",
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            propertyDetails.title,
            propertyDetails.bedrooms,
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            if(response.status == 1){
                // Extract data for chart
                const filteredData = response.data.filter((item) =>  item.avg_price > 0 );
                const chartData = filteredData.map(item => ({
                    x: item.evidence_month,
                    y: item.avg_price 
                }));
                setPriceTrendsChangesDataProject(filteredData);
                setPropertyValue(CalculatePercentage(filteredData, 2));  
                setPriceTrendsData_Project(chartData);

                setLoaderProject(false)

                setChangeIsProject(true)
            }
            else{
                setLoaderProject(false);
                setErrorProject('There is No Data');
                setChangeIsProject(true)
            }

        } catch (error) {
            setLoaderProject(false);
            setErrorProject(error);
            setChangeIsProject(true)
        }
    }

    const Property_Monitor_API_For_Community_Trends = async() => {
        setCommunityLoader(true)
        const COMMUNITY_TRENDS_API_CONFIG = GET_COMMUNITY_TRENDS_API(
            propertyDetails.location,
        );
        
        try {
            const response = await httpRequest(COMMUNITY_TRENDS_API_CONFIG);
            console.log("****** Response Community Trends ******* ", response );
            if(response.status == 1){
                // Extract data for chart
                setCommunityTrendsData(response.data);
                setCommunityLoader(false);
            }
            else{
                setCommunityLoader(false);
            }
        } catch (error) {
            setCommunityLoader(false);
        }
    }

    const RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT = async() => {
        // setLoaderProperty(true)
        const API_POPERTY_PRICE_AS_PER_AREA_CONFIG = GET_RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT_API(
            propertyDetails.developer,
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            propertyDetails.location,
            propertyDetails.bedrooms,
            propertyDetails.bedrooms,
        );
        
        try {
            const response = await httpRequest(API_POPERTY_PRICE_AS_PER_AREA_CONFIG);
            console.log("**** API_POPERTY_PRICE_AS_PER_AREA_CONFIG ****** ", response);

            if(response.status == 1){
                setpropertyPrice([]);
            setLoaderProperty(false)

            }
            else{
                setpropertyPrice([]);
            setLoaderProperty(false);

            }

        } catch (error) {
            setLoaderProperty(false)
        }
    
    }

    const handleAreaChange = (event) => {
        setSelectedArea(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filteredData = data[selectedArea].filter(item => item.year >= startDate && item.year <= endDate);

    const handleAreaForRentalChange = (event) => {
        setSelectedAreaForRental(event.target.value);
    };

    // Price data
      const priceData = [
         13500,
         12000,
         13300,
         13000,
         12500,
         14000,
         13500,
         14050,
         15000,
         15500,
         16000,
         16500,
      ];

      const transactionData = [
        { id: 1, area: 'Arabian BHills Estate', transactionDate: '2024-02-10', amount: 150000 },
        { id: 2, area: 'villa in Harmony', transactionDate: '2024-02-09', amount: 200000 },
        { id: 3, area: 'Villa For Rent, Tilal Al Ghaf', transactionDate: '2024-02-08', amount: 180000 },
        { id: 4, area: 'Dist one west villa', transactionDate: '2024-02-07', amount: 220000 },
        { id: 5, area: '6 Bedroom villa Emerald Hills', transactionDate: '2024-02-06', amount: 190000 },
        // Add more transaction data as needed
      ];

      const monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

      const convertMonth = (monthString) => {
        const [month, year] = monthString.split('-');
        const monthIndex = parseInt(month) - 1;
        return `${monthNames[monthIndex]}`;
    };
      
    const newChartData = [
        {
            "evidence_month": "5-2023",
            "avg_price": 10019509
        },
        {
            "evidence_month": "6-2023",
            "avg_price": 8139785
        }
    ];

    // const chartMonths = newChartData.map(data => convertMonth(data.evidence_month));
    const chartMonths = ["May", "June"];

    const monthFormatterChart = (month) => month; 

    const prices = priceTrendsData.map(data => data.total_value);

    const series = [{
        data: prices.splice(0, -1)
    }];
  
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const price = payload[0].value;
          const formattedPrice = AmountSaperator(price); // Call your function here
          return (
            <div style={{ backgroundColor:'white', border:'0.5px solid #000', padding:'0.25rem', borderRadius:5 }}>
              {/* <p className="label">{`Area: ${label}`}</p>
              <p className="intro">{`Price: ${formattedPrice}`}</p> */}
              <Typography variant='subtitle2' >
                    {`Area: ${label}`}
                </Typography>
                <Typography variant='subtitle2' >
                    {`Price: ${formattedPrice}`}    
                </Typography>
            </div>
          );
        }
      
        return null;
      };

    const CustomTooltipForPerformingAreas = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const price = payload[0].value;
            const formattedPrice = AmountSaperator(price); // Call your function here
            return (
                <div style={{ backgroundColor:'white', border:'0.5px solid #000', padding:'0.25rem', borderRadius:5 }}>
                {/* <p className="label" style={{ fontFamily:"Montserrat" }} >{`Area: ${label}`}</p>
                <p className="intro" style={{ fontFamily:"Montserrat" }} >{`Price / sqft: ${formattedPrice}`}</p> */}
                <Typography variant='subtitle2' >
                    {`Area: ${label}`}
                </Typography>
                <Typography variant='subtitle2' >
                    {`Price / sqft: ${formattedPrice}`}
                </Typography>
                </div>
            );
        }
        
        return null;
    };



    // const chartData = priceTrendsData.slice(0, 12).map(item => ({
    //     month: moment(item.x, 'M-YYYY').format('MMM YY'), // Format the month using Moment.js
    //     total_value: item.y // Use the total_value as the Y-axis data
    // }));

    const chartDataArea = priceTrendsData_Area.map(item => ({
        month: moment(item.x, 'M-YYYY').format('MMM YY'), // Format the month using Moment.js
        total_value: item.y // Use the total_value as the Y-axis data
    }));

    const chartDataProject = priceTrendsData_Project.map(item => ({
        month: moment(item.x, 'M-YYYY').format('MMM YY'), // Format the month using Moment.js
        total_value: item.y // Use the total_value as the Y-axis data
    }));

    const chartCommunityData = communityTrendsData.map(item => ({
        date: moment(item.evidence_date).format('MMM YY'), // Format the month using Moment.js
        change: item.monthly_change, // Use the total_value as the Y-axis data
        amount: item.price, // Use the total_value as the Y-axis data
    }));
 
    return( 
        <>
            <div 
                className='top_lead_detail_design' 
                style={{
                    backgroundImage: `url(${propertyDetails.images !== null && propertyDetails.images.length > 0 ? `${propertyImagesUrl+"/"+propertyDetails.images[0].name}` : "https://cdn-ijcmf.nitrocdn.com/wgZJdyCfHniZRfljxBqkJVQJnyFdTIeP/assets/images/optimized/rev-66c19d9/mypropertymanager.ae/wp-content/uploads/2023/03/homepage-hero-banner-min.jpg"})`,
                    width: "100%",
                    minHeight: '25rem',
                    backgroundSize: 'cover', //cover
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#000',  // Fallback color
                }} 
            >   
                
            </div>
            <Container maxWidth={'xl'} >
                <div style={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:'column', marginTop:'0.5rem', }}>
                  
                    <Grid item /* spacing={2} */ className='property_details_1' marginTop={'3rem'} >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography variant="h5"  style={{ fontFamily:'Montserrat'}}>
                                {propertyDetails.title ? propertyDetails.title : ""}
                            </Typography>
                            <Typography variant="h5"  style={{ fontFamily:'Montserrat'}}>
                                {propertyDetails.developer ? propertyDetails.developer : ""} 
                            </Typography>
                            <Typography variant="h5" style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start" }} >
                                {propertyDetails.price ? AmountSaperator(propertyDetails.price) : 0} AED
                            </Typography >
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            {
                                nextInstallmentObj ?
                                (
                                    // nextInstallmentObj.installment_details == "𝐅𝐮𝐥𝐥𝐲 𝐏𝐚𝐢𝐝"?
                                    nextInstallmentObj.installment_details == "Fully Paid"?
                                    null
                                    :
                                    <Typography variant="h5" style={{ display:'flex', flexDirection:"column", alignItems:"flex-end", justifyContent:"flex-start" }} >
                                        <Typography style={{ fontSize: '0.85rem', fontWeight:'normal' }} >
                                            NEXT INSTALLMENT
                                        </Typography >
                                        <Typography style={{ fontSize: '1.25rem', fontWeight:'bold' }} >
                                            AED { nextInstallmentObj? AmountSaperator(nextInstallmentObj.amount) : "--"} 
                                        </Typography>
                                        <Typography style={{ fontSize: '0.85rem', fontWeight:'300' }} >
                                            { nextInstallmentObj && nextInstallmentObj.date !== null  ? moment(nextInstallmentObj.date).format('Do MMMM YYYY') : "--"}
                                        </Typography>
                                    </Typography >
                                )
                                :
                                null
                            }
                        </Grid>
                    </Grid>

                    <Grid item /* spacing={2} */ className='property_details_2' marginTop={'3rem'}  >
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className='property_details_2_First_Grid' >
                            <Typography style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'normal', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                PROPERTY DETAILS
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Project: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.sub_title ? propertyDetails.sub_title : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                UNIT NO: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.unit_no ? propertyDetails.unit_no : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                BUA: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.area_size ? propertyDetails.area_size : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Bedroom <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.bedrooms ? propertyDetails.bedrooms : " -- "} </Typography>
                            </Typography>
                            {/* <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                BA: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> -- </Typography>
                            </Typography> */}
                            {/* <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                VIEW: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> HOTEL VIEW </Typography>
                            </Typography> */}
                            {/* <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                PARKING: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> -- </Typography>
                            </Typography> */}
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                {/* Handover: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> <a href={`${propertyDocumentsUrl}/${propertyDetails.hand_over}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}> {propertyDetails.hand_over ? propertyDetails.hand_over : " -- "} </a> </Typography> */}
                                Handover: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.hand_over ? propertyDetails.hand_over : " -- "}  </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Floor Plan: 
                                {
                                    propertyDetails.floor_plan ? 
                                        <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> <a href={`${propertyDocumentsUrl}/${propertyDetails.floor_plan}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}> {propertyDetails.floor_plan ? "View" : " -- "} </a> </Typography>
                                        :
                                        "  Not Available"
                                }
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Title Deed / Contract:
                                {
                                    propertyDetails.title_deed ? 
                                        <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}>  <a href={`${propertyDocumentsUrl}/${propertyDetails.title_deed}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}> {propertyDetails.title_deed ? "View" : " -- "} </a> </Typography>
                                        :
                                        "  Not Avaialble"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} xl={8} className='property_details_2_Second_Grid' >
                            {
                                loader2?
                                <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                    <LottieAnimationLoader />
                                </div>
                                :
                                (
                                    !loader2 && paymentData.length == 0 ?
                                    <div style={{ border:"solid 0.75px lightgray",  display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:400 }} >
                                        <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                            No Data Available
                                        </Typography>
                                    </div>
                                    :
                                    <>
                                        <TableComponent xData={paymentData} />
                                        {/* <Typography variant="subtitle1" gutterBottom style={{ fontWeight:'normal', marginTop:'1rem', fontFamily:'italic' }}>
                                            {`Estimated Service Charges AED 45 sqft internal - AED 11.25 external`}
                                        </Typography> */}
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                    
                    <div className='detail-container'>
                        <div style={{ marginTop: '2.5rem' }}>

                            {
                                !loaderArea && chartDataArea.length == 0 ?
                                    null
                                    :
                                    <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                        Average Residential Sales Price
                                    </Typography>
                            }
                            
                            <Grid container marginTop={'2rem'} marginBottom={'2rem'}>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom:'1rem' }} >
                                    <Typography variant="subtitle1"  style={{ fontSize:'1.35rem', fontWeight: '500', fontFamily:'Montserrat' }}>
                                        {
                                            !loaderArea && chartDataArea.length == 0 ?
                                            null
                                            :
                                            (
                                                propertyDetails.location?propertyDetails.title.toUpperCase()+", "+propertyDetails.location.toUpperCase():" "
                                            )
                                        }
                                    </Typography>
                                    {
                                            loaderArea?
                                            <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <LottieAnimationLoader />
                                            </div>
                                            :
                                            (
                                            !loaderArea && chartDataArea.length == 0 ?
                                                null
                                                :
                                                <ListingBarChartComp xRawData={chartDataArea} xChanges={sChangeIsArea} xPriceChangesData={priceTrendsChangesDataArea} />
                                        )
                                    }
                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ marginBottom:'1rem'}} >
                                    <Typography variant="subtitle1" style={{ fontSize: '1.35rem', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        {propertyDetails.title?propertyDetails.title.toUpperCase():" "}
                                    </Typography>
                                    {
                                        loaderProject?
                                        <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                            <LottieAnimationLoader />
                                        </div>
                                        :
                                        (
                                            !loaderProject && chartDataProject.length == 0 ?
                                            <div style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <img src={BarLoader} height={300} width={'90%'}  />
                                                <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                                    No Data Available
                                                </Typography>
                                            </div>
                                            :
                                            <ListingBarChartComp xRawData={chartDataProject} xChanges={sChangeIsProject} xPriceChangesData={priceTrendsChangesDataProject} />
                                        )
                                    }
                                </Grid> */}
                            </Grid>



                            {
                                !communityLoader && chartCommunityData.length == 0 ?
                                    null
                                    :
                                    <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                        Community Price Trends
                                    </Typography>
                            }

                            <Grid container marginTop={'2rem'} marginBottom={'2rem'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom:'1rem' }} >
                                    {
                                            communityLoader?
                                            <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <LottieAnimationLoader />
                                            </div>
                                            :
                                            (
                                            !communityLoader && chartCommunityData.length == 0 ?
                                                null
                                                :
                                                <div style={{ flexDirection:"column", padding: '20px', display:"flex", alignItems:"center", justifyContent:"center", }}>
                                                    {
                                                        communityTrendsData && communityTrendsData.length > 0 &&
                                                        <h4>{moment(communityTrendsData[0].evidence_date).format("MMM YYYY")} to {moment(communityTrendsData[communityTrendsData.length - 1].evidence_date).format("MMM YYYY")}</h4>
                                                    }
                                                    <h5>Change in Residential Sales Price</h5>
                                                    <h6>{propertyDetails.location}</h6>
                                                    <CommunityPriceTrends xCommunityChartData={chartCommunityData} xCommunityFullData={communityTrendsData} />
                                                </div>
                                        )
                                    }
                                </Grid>
                            </Grid>





                                        { /******************************* Dubai Property RENTAL CHANGES **************************/ }




                            {/* <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                DUBAI PROPERTY <span style={{ fontSize: '1.15rem', fontWeight: '500', textDecoration:'underline' }}>Rental Changes</span> For {propertyDetails.bedrooms?propertyDetails.bedrooms+" BD ":" "}{propertyDetails.property_type?propertyDetails.property_type:" "}
                            </Typography>


                            <Grid container marginTop={'1rem'} marginBottom={'2rem'} >
                                <div className={classes.chartContainer}>
                                    <ResponsiveContainer>
                                        {
                                            loaderRent?
                                             <div style={{ border:"solid 0.75px lightgray", display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                 <LottieAnimationLoader />
                                             </div>
                                             :
                                             (
                                                !loaderRent && sRentalChangesData.length == 0 ?
                                                <div style={{ display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", height:400 }} >
                                                    <img src={LineChartLoader} height={400} width={'90%'}  />
                                                    <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                                        No Data Available
                                                    </Typography>
                                                </div>
                                                :
                                                <AreaChart
                                                    data={sRentalChangesData}
                                                    margin={{
                                                        top: 10,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                    >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis 
                                                        dataKey={"area"} 
                                                        fontSize={'0.65rem'} 
                                                        fontFamily='Montserrat' 
                                                        interval={0}
                                                        tick={({ x, y, payload }) => (
                                                            <text 
                                                                x={x} 
                                                                y={y} 
                                                                dy={16} 
                                                                textAnchor="middle" 
                                                                fill="#666" 
                                                                fontSize={'0.65rem'}
                                                                fontFamily='Montserrat'
                                                            >
                                                                {payload.value.length > 10 ? `${payload.value.substring(0, 16)}...` : payload.value}
                                                            </text>
                                                        )}
                                                    />
                                                    <YAxis 
                                                        fontSize={'0.65rem'} 
                                                        tickFormatter={(value) => `AED ${AmountSaperator(value)}`}
                                                    />
                                                    <Tooltip content={CustomTooltip} />
                                                    <Area type="monotone" dataKey="price" stroke="#0F2027" fill="#0F2027" />
                                                </AreaChart>
                                             )
                                        }
                                    </ResponsiveContainer>
                                </div>
                            </Grid> */}






                                        { /******************************* Dubai Property SALES CHANGES **************************/ }




                            
                            {/* <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                DUBAI PROPERTY <span style={{ fontSize: '1.15rem', fontWeight: '500', textDecoration:'underline' }}> Sales Price Changes</span> For {propertyDetails.bedrooms?propertyDetails.bedrooms+" BD ":" "}{propertyDetails.property_type?propertyDetails.property_type:" "}
                            </Typography>

                            <Grid container marginTop={'0.25rem'} marginBottom={'2rem'} >
                                <div className={classes.chartContainer}>
                                    <ResponsiveContainer>
                                        {
                                            loader?
                                            <div style={{ border:"solid 0.75px lightgray", display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <LottieAnimationLoader />
                                            </div>
                                             :
                                             (
                                                    !loader && sTransactionData.length == 0 ?
                                                    <div style={{ display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", height:400 }} >
                                                        <img src={LineChartLoader} height={400} width={'90%'}  />
                                                        <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                                            No Data Available
                                                        </Typography>
                                                    </div>
                                                    :
                                                    <AreaChart
                                                        data={sSaleChangesData}
                                                        margin={{
                                                            top: 10,
                                                            right: 30,
                                                            left: 0,
                                                            bottom: 0,
                                                        }}
                                                        >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis 
                                                            dataKey={"area"} 
                                                            fontSize={'0.65rem'} 
                                                            fontFamily='Montserrat' 
                                                            interval={0}
                                                            tick={({ x, y, payload }) => (
                                                                <text 
                                                                    x={x} 
                                                                    y={y} 
                                                                    dy={16} 
                                                                    textAnchor="middle" 
                                                                    fill="#666" 
                                                                    fontSize={'0.65rem'}
                                                                    fontFamily='Montserrat'
                                                                >
                                                                    {payload.value.length > 10 ? `${payload.value.substring(0, 16)}...` : payload.value}
                                                                </text>
                                                            )}
                                                        />
                                                        <YAxis 
                                                            fontSize={'0.65rem'} 
                                                            tickFormatter={(value) => `AED ${AmountSaperator(value)}`}
                                                        />
                                                        <Tooltip content={CustomTooltip} />
                                                        <Area type="monotone" dataKey="price" stroke="#0F2027" fill="#0F2027" />
                                                    </AreaChart>
                                             )
                                        }
                                    </ResponsiveContainer>
                                </div>
                            </Grid> */}

                        </div>

                    </div>

                </div>
            </Container>
            <Typography  style={{ backgroundColor:'#222', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat',color:'white', letterSpacing:2, lineHeight:'2rem', padding:'0.5rem' }} >
                LATEST TRANSACTION FOR {propertyDetails.bedrooms?propertyDetails.bedrooms+" BD ":" "}{propertyDetails.property_type?propertyDetails.property_type.toUpperCase():" "}
            </Typography>
            <TableContainer component={Paper} style={{ marginBottom:'5rem', marginTop:'-0.5%' }} >
                <Table>
                    <TableHead>
                    <TableRow sx={{ backgroundColor: '#222', color: '#fff' }}>
                        <TableCell align='left' style={{ color: 'white' }} >Evidence Date</TableCell>
                        <TableCell align='left' style={{ color: 'white' }} >Master Development</TableCell>
                        <TableCell align='left' style={{ color: 'white' }} >Community</TableCell>
                        {/* <TableCell align='left' style={{ color: 'white' }} >Property</TableCell> */}
                        <TableCell align='center' style={{ color: 'white' }} >Transaction Date</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >Area in sqf</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >Area in sqm</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >
                            Price in {currency == 'AED'?"AED":"USD"}
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleCurrencyMenu}
                            >
                                <ArrowDropDownIcon color='white' style={{ color:"white" }} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {sTransactionData.map((transaction, index) => (
                        <TableRow style={{ backgroundColor: index % 2 == 0 ? 'white' : 'lightgray'  }} key={transaction.id}>
                            <TableCell align='left' >{transaction.transactionDate}</TableCell>
                            <TableCell align='left' >{transaction.area}</TableCell>
                            <TableCell align='left' >{transaction.Community}</TableCell>
                            {/* <TableCell align='left' >{transaction.Sub_Community}</TableCell> */}
                            <TableCell align='center' >{transaction.transactionDate}</TableCell>
                            <TableCell align='center' >{AmountSaperator(transaction.area_sqft)} sqf</TableCell>
                            <TableCell align='center' >{AmountSaperator(transaction.area_sqmt)} sqm</TableCell>
                            <TableCell align='center' >
                                {transaction.amount == null ? 
                                    'AED 0' 
                                    : 
                                    // `AED ${AmountSaperator(transaction.amount)}`
                                    `${formatPrice(transaction.amount)}`
                                }
                                </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>

                {
                    loader &&
                    <div style={{ width: "100%", height:'40rem', display: 'flex', alignItems: "center", alignItems: "center", justifyContent: "center" }} >
                        <div style={{ display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                            <LottieAnimationLoader />
                        </div>
                    </div>
                }

                {
                    !loader && sTransactionData.length == 0 &&
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                        <Typography variant="subtitle2" style={{ color:'gray', fontSize: '1.15rem', fontFamily: 'Montserrat', marginTop: '2rem', marginBottom: '2rem' }}>
                            There are limited transactions in this area - No Data Available
                        </Typography>
                    </div>
                }
            </TableContainer>
            <Menu
                id="menu-appbar"
                anchorEl={currencyMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(currencyMenu)}
                onClose={hadleCloseCurrencyMenu}
                sx={{
                    boxShadow:'none'
                }}
                >
                {
                    currency == 'AED'?
                    <MenuItem onClick={handleToggleCurrency} >
                        <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} > USD</Typography>
                    </MenuItem>
                    :
                    <MenuItem onClick={handleToggleCurrency} >
                        <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >AED</Typography>
                    </MenuItem>
                }
            </Menu>
        </>
    )   
}

export default ListingsDetails;