import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';
import { setDate } from 'date-fns';

const AddResidential = ({locations,xCLoseModal}) => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [status, setStatus] = useState('Active');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [documentName, setDocumentName] = useState(null);
    const [document, setDocument] = useState(null);

    const [countries,setCountries] = useState([])
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [password,setPassword] =useState('')
    const token = useSelector((state) => state.login.token);
    const [year,setYear] =useState('')
    const [location,setLocation] =useState('')



    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    
      // State to store the prices for each month
      const [prices, setPrices] = useState(
        months.reduce((acc, month) => ({ ...acc, [month]: '' }), {})
      );
    
      // Handle the change in the input field
      const handlePriceChange = (month, value) => {
        setPrices({
          ...prices,
          [month]: value
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        if(validate()){
        const data = {year:year,location:location,prices:prices}
        axios.post(baseUrl+"/api/residential", data, {
            headers: {
                'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
                ...axiosConfig.headers // Include any other headers
            }
        })
        .then((res) => {
           
            
          if(res.data.success){
            setYear('')
            setLocation('')
              xCLoseModal()
  
          }
        })
        .catch((error) => {
         
        //   xCLoseModal()
            console.error("Error:", error);
        });
    }
      };

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };

    useEffect(()=>{
      axios.get(baseUrl+"/api/countries",axiosConfig)
      .then((res) =>{
        if(res.data.success){
            setCountries(res.data.countries)
        }
      }).catch((err) =>{
        console.log("err",err);
    })
    },[])
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handlePasswordChange = (event) =>{
        setPassword(event.target.value)
    }
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
     
    };
  
    const handleDobChange = (event) => {
      setDob(event.target.value);
    };
  
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };
  
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };


    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!year) {
          isValid = false;
          errors["year"] = "Year is required";
        }

        if (!location) {
            isValid = false;
            errors["location"] = "Location is required";
          }

      

         

          

        setErrors(errors)
        return isValid;
      };
  
    const handleProfileUpdate = () => {
   
    if(validate()){
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phoneNumber);
      formData.append('email', email);
      formData.append('dob', dob);
      formData.append('status', status);
      formData.append('address', address);
      formData.append('country', country);
      formData.append('image', image); // Assuming image is a File object
      formData.append('document', document); // Assuming document is a File object
      formData.append('password', password);
      
      axios.post(baseUrl+"/api/owners", formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
              ...axiosConfig.headers // Include any other headers
          }
      })
      .then((res) => {
          console.log("res server", res.data);
      })
      .catch((error) => {
        let errors = {}
        errors["email"] = error.response.data.data.email[0]
    setErrors(errors)
          console.error("Error:", error);
      });
      xCLoseModal()
    }else{
        return
    }
}

    // Function to handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
        setImage(reader.result);
        };

        if (file) {
        reader.readAsDataURL(file);
        }
    };

    // Function to handle image update
    const handleImageUpdate = () => {
        // Implement your image update logic here, for example, send the image to the server
        console.log('Image Updated:', image);
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        setDocument(file);
        setDocumentName(file.name)
    };

    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

<form onSubmit={handleSubmit}>
    <div className='row'>
        <div className='col-md-5'>
    <div className='form-group mb-3'>
        <label>Year:</label>
        <select className='form-select' onChange={(e) =>setYear(e.target.value)}>
        <option value="">...select...</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
        </select>
        <div className="text-danger">
                          {errors.year}
                        </div>
    </div>
    
    </div>
    <div className='col-md-7'>
  
                            <label> Location</label>
                            <select
                                className='form-select'
                                value={location}
                                onChange={(e) =>setLocation(e.target.value)}
                            >
                                <option value="">...select...</option>
                                {locations.map((location,index)=>(
                                    <option key={index} value={location.value}>{location.label}</option>
                                ))}
                                
                            </select>
                            <div className="text-danger">
                          {errors.location}
                        </div>
    </div>
    </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Month</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr key={month}>
              <td>{month}</td>
              <td>
                <input
                  type="text"
                  className='form-control'
                  value={prices[month]}
                  onChange={(e) => handlePriceChange(month, e.target.value)}
                  placeholder="Enter price"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className='btn btn-success' style={{backgroundColor:'black',color:'white'}} type="submit">Save data</button>

    </form>
            </div>
        </div>
    )
}

export default AddResidential;